.two-column-submenu .ant-menu-item-group-list {
	display: flex;
	flex-wrap: wrap;
	width: 50vw;
}

.two-column-submenu .ant-menu-item-group-list > .ant-menu-item {
	width: 50%; /* 将宽度设置为 50% 来实现两列显示 */
	box-sizing: border-box;
}

.two-column-submenu .ant-menu-item-selected {
	background-color: transparent !important;
}

.two-column-submenu .ant-menu-item-selected {
	color: inherit !important;
}
